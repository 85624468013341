.header_container {
  position: relative;
  width: 100%;
  max-width: 85em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.78em;
  padding-left: 2.78em;
}

.footer__text {
  text-decoration: none;
  color: #000;
  font-size: 1em;
}

.footer__center {
  font-family: "Sofia Pro", sans-serif;
  font-size: 7px;
}

.nav_gap {
  gap: 5px;
}

@media screen and (max-width: 991px) {
  body {
    font-size: 10px;
  }

  h2 {
    font-size: 3em;
  }

  h4 {
    font-size: 1.8em;
  }

  p {
    font-size: 1.4em;
  }

  .header_container {
    position: relative;
    width: 100%;
    max-width: 85em;
    margin-right: auto;
    margin-left: auto;
    padding-right: 2.78em;
    padding-left: 2.78em;
  }

  .navbar {
    height: 5em;
  }

  .roadmap_container {
    padding-right: 4em;
    padding-left: 4em;
  }

  .navbar__wrap {
    position: static;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .brand {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .nav-menu {
    padding-top: 1.5em;
    padding-bottom: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #000;
    background-color: #fff;
  }

  .nav__list {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav__link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.6em;
  }

  .roadmap_container-md {
    padding-right: 4em;
    padding-left: 4em;
  }

  .header__text {
    display: none;
  }

  .section.section__3 {
    padding-top: 0em;
  }

  .section.section__6 {
    padding-top: 0em;
  }

  .about__image {
    padding: 2em;
  }

  .about__text {
    font-size: 1.6em;
  }

  .about__content {
    padding: 2em 4em 4em;
  }

  .about__content-bottom {
    bottom: -6.8em;
  }

  .sm_text {
    font-size: 1.4em;
  }

  .squre.squre-2 {
    left: -3em;
  }

  .shop-l {
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .p_text {
    font-size: 1.4em;
  }

  .teams {
    padding-left: 4em;
  }

  .team__image {
    width: 8em;
  }

  .squre-__team {
    left: -5em;
  }

  .squre-__team-2 {
    left: -5em;
  }

  .squre-__team-3 {
    left: -5em;
  }

  .squre-__team-4 {
    left: -5em;
  }

  .squre-__team-5 {
    left: -5em;
  }

  .footer {
    background-image: url("../../pages/home/images/footer__bg.png");
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg__footer {
    display: none;
    height: 112em;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .footer__text {
    font-size: 1em;
  }

  .img__fluid {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .menu-button {
    padding: 0px;
    font-size: 35px;
  }

  .menu-button.w--open {
    background-color: transparent;
    color: #000;
  }

  .image {
    left: 57%;
    width: 28em;
  }

  .footer__l {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 3;
  }

  .footer__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Sofia Pro", sans-serif;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    height: 6em;
  }

  .header {
    height: auto;
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .about {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about__image {
    border-radius: 0em 0em 1.5em 1.5em;
  }

  .about__content {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .squre.squre-2 {
    top: 1em;
  }

  .shop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .shop-l {
    border-style: solid;
    border-width: 2px 2px 0px;
    border-color: #fff;
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    border-bottom-left-radius: 0em;
  }

  .shop-r {
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
  }

  .shop_content-bottom {
    bottom: -5em;
  }

  .team__item-l {
    width: 100%;
    border-bottom: 1px solid #fff;
  }

  .team__item-l-info {
    border-right-width: 0px;
  }

  .team__item-r {
    width: 100%;
    margin-left: 0em;
    padding: 1em;
    text-align: center;
  }

  .team__item-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .roadmap__item {
    width: 100%;
  }

  .roadmap__item.roadmap__item-last {
    position: relative;
    width: 100%;
    margin-bottom: 0em;
    padding-right: 0em;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    text-align: left;
  }

  .squre-__r-5 {
    left: -5.5em;
    top: 50%;
    right: auto;
    bottom: auto;
  }

  .dotted__block {
    width: 100%;
  }

  .footer {
    padding-top: 2em;
    padding-bottom: 2em;
  }


  .footer__logo {
    margin-right: 0em;
    margin-bottom: 2em;
  }

  .footer__text {
    font-size: 1.4em;
    text-align: center;
  }

  .footer_r {
    margin-top: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer_socials {
    margin-top: 1em;
    margin-left: 0em;
  }

  .s__icon {
    height: 2.4em;
  }

  .img__fluid {
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
  }

  .faq__name {
    font-size: 1.6em;
  }

  .image {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .roadmap_container {
    padding-right: 2em;
    padding-left: 2em;
  }

  .roadmap_container-md {
    padding-right: 2em;
    padding-left: 2em;
  }

  .about__content {
    padding-right: 2em;
    padding-left: 2em;
  }

  .sm_text.sm_text-1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .squre {
    right: -3em;
  }

  .squre.squre-3 {
    left: -5em;
    top: 0%;
    right: auto;
    bottom: auto;
  }

  .shop_content-bottom {
    z-index: 1;
  }

  .teams__head {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .team__item-r {
    width: 100%;
  }

  .video__bottom-info {
    width: 90%;
    max-width: none;
  }

  .roadmap_head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer__text {
    text-align: center;
  }

  .faq__name {
    width: 85%;
  }
}