:root {
  --color-yellow: #ffea42;
  --color-deep-pink: #ff0080;
  --text-shadow: -1.5px 0 black, 0 1.5px black, 1.5px 0 black, 0 -1.5px black;
  --stripe-size: 100px;
  --color-red: #c44;
  --color-dark-grey: #313131;
  --color-light-grey: #9e9e9e;
  --duration: 2s;
  --color-brown: #9b7a39;
  --color-red2: #fd1cbd;
}
