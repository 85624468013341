@font-face {
  font-family: Insanibc;
  src: url("./font/Insanibc.ttf");
}

@font-face {
  font-family: Insanibu;
  src: url("./font/Insanibu.ttf");
}

body {
  margin: 0;
  font-family: Insanibc;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
