.homeWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

@media screen and (max-width: 767px) {
  .homeWrapper {
    height: 190vh;
  }
}