.section.section__6 {
  z-index: 1;
  padding-top: 0;
}

.section {
  position: relative;
  z-index: 1;
}

.qa_head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.sm_text.sm_text-1 {
  -webkit-box-ordinal-group: 0;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}

.heading__block {
  position: relative;
}

.squre.squre-5 {
  left: auto;
  top: 50%;
  right: -5.5em;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.faq__list {
  margin-top: 2em;
}

.faq__item {
  position: relative;
  margin-bottom: 1em;
  padding: 1.67em;
  border-radius: 1em;
  background-color: #fff;
  cursor: pointer;
}

.faq__item-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq__name {
  font-size: 1.39em;
}

.faq__arr {
  height: 1em;
}

.faq__item-content {
  overflow: hidden;
}

.faq__item-content-wrap {
  padding-top: 2em;
  padding-bottom: 1em;
}

@media screen and (max-width: 991px) {
  .section.section__6 {
    padding-top: 0em;
  }
}

@media screen and (max-width: 479px) {
  .qa_head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .squre.squre-5{
    display: none;
  }
}
