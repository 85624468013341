body {
  font-family: Insanibc;
  color: #333;
  font-size: 1vw;
  line-height: 1.25;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

p {
  margin-bottom: 0px;
}

img {
  display: inline-block;
  width: 100%;
  max-width: 100%;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container.hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 16.62em;
  padding-bottom: 6.81em;
  padding-left: 17.42em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 9em;
}

.container.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 3.88em 9.96em 4.03em 10.83em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.container.about {
  padding: 7.25em 15.96em 8.05em 17.42em;
}

.container.artist {
  position: relative;
  padding: 7.25em 16em 8.05em 16em;
}

.container.team {
  position: relative;
  padding: 0 15.74em 10.98em 16.76em;
}

.container.roadmap {
  padding: 13em 15.81em 9.08em 16.76em;
}

.container.footer {
  padding: 4.1em 10.03em 4.1em 10.76em;
}

.section.hero {
  position: relative;
  background: #fff;
}

.section.about {
  background-color: #000;
}

.section.artist {
  background-color: #000;
  padding-bottom: 6.94em;
}

.section.team {
  background-color: #000;
  padding-top: 0;
  padding-bottom: 0;
}

.section.gallery {
  position: relative;
  padding-top: 0;
  z-index: 2;
}

.section.roadmap {
  position: relative;
  background-color: #000;
}

.section.footer {
  background-image: url("./images/彩虹背景-底部part.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.navigation {
  background-color: #fff;
}

.nav-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav-link {
  margin-right: 1.8em;
  margin-left: 0px;
  padding: 0px 0px 0.2em;
  -webkit-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  font-size: 1.04em;
  line-height: 1.43;
}

.nav-link:hover {
  box-shadow: 0 2px 0 0 #000;
  text-decoration: none;
}

.nav-link.w--current {
  color: #000;
}

.nav-link.last {
  margin-right: 0em;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-logo {
  display: none;
}

.main-logo {
  width: 10.25em;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.main-logo:hover {
  opacity: 0.85;
}

.content-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-wrapper.about {
  position: relative;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: none;
  border-width: 0.1em;
  border-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 0 0.1em #fff;
}

.content-wrapper.parts {
  position: relative;
  z-index: 0;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: none;
  border-width: 0.1em;
  border-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 0 0.1em #fff;
}

.content-wrapper.parts.high {
  z-index: 22;
}

.content-wrapper.team {
  position: relative;
  z-index: 11;
  padding: 3.95em 5em 5.86em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: none;
  border-width: 0.1em;
  border-color: #fff;
  border-radius: 1em;
  box-shadow: 0 0 0 0.1em #fff;
}

.content-wrapper.team.high {
  z-index: 22;
}

.content-wrapper.road {
  position: relative;
  z-index: 11;
  padding: 3.95em 5em 5.86em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-style: none;
  border-width: 0.1em;
  border-color: #fff;
  border-radius: 1em;
  background-image: url("./images/内容框new.png");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 0 0 0.1em #fff;
}

.content-wrapper.road.high {
  z-index: 22;
}

.content-wrapper.footer {
  padding: 2.12em 6.66em;
  border-radius: 1em;
  background-color: #fff;
}

.content-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.content-left.white {
  width: 54%;
  padding: 3em 3em 4em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background-color: #fff;
  font-weight: 300;
}

.content-left.parts {
  z-index: 999;
  width: 58%;
  padding: 0em;
  border-radius: 0em 1em 1em 0em;
  background-color: transparent;
  font-weight: 300;
}

.content-left.full {
  width: 100%;
  margin-bottom: 2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background-color: transparent;
  color: #fff;
  font-weight: 300;
}

.content-left.full._2nd {
  margin-bottom: 3.95em;
}

.content-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.content-right.about {
  width: 53%;
  padding: 4em 3em 4em 4em;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
  background-color: #000;
  color: #fff;
}

.content-right.parts {
  width: 42%;
  padding: 4em 3em 4em 4.5em;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  background-color: #000;
  color: #fff;
}

.content-right.full {
  width: 100%;
  margin-top: 2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 1em;
  background-color: #000;
  box-shadow: inset 0 0 0 0.1em #fff;
  color: #fff;
}

.content-right.thirdWrapper {
  width: 100%;
  margin-top: 2em;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: space-between;
  -ms-flex-pack: start;
  justify-content: left;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
}

.content-right.thirdWrapper .third {
  width: 32%;
  border-radius: 1em;
  background-color: #000;
  box-shadow: inset 0 0 0 0.1em #fff;
  color: #fff;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-right.thirdWrapper .third .separator {
  width: 0.15em;
  height: 2.5em;
  margin: 0 1em 0 1em;
  background-color: #fff;
}

.content-right.thirdWrapper .third .separator.first {
  margin: 0 0.5em 0 0.5em;
}

.content-right.thirdWrapper .third .content-title.team-member {
  width: auto;
}

.display-first {
  margin-top: 1em;
  margin-bottom: 0px;
  font-size: 1.3em;
  line-height: 1.25;
  font-weight: 300;
}

.display-second {
  margin-top: 0.3em;
  margin-bottom: 0px;
  color: #000;
  font-size: 1.4em;
  line-height: 1.25;
  font-weight: 300;
}

.right-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #fff;
  font-weight: 300;
}

.content-title {
  font-family: Insanibc;
  font-size: 2.53em;
  line-height: 0.98;
  font-weight: 400;
  text-align: right;
}

.content-title.parts {
  font-size: 2.55em;
}

.content-title.parts.road {
  font-size: 1.65em;
  font-weight: 300;
  text-align: left;
}

.content-title.parts.road.right {
  text-align: right;
}

.content-title.team-member {
  width: 5em;
  font-size: 1.29em;
  text-align: left;
}

.content-sub {
  margin-top: 3em;
  font-size: 1.2em;
  line-height: 1.63;
  font-weight: 300;
  font-family: "Sofia Pro", sans-serif;
}

.content-sub.parts {
  margin-top: 2.5em;
}

.content-sub.team {
  margin-top: 0em;
  font-size: 1em;
  line-height: 1.67;
  /* padding-right: 6em; */
}

.content-sub.road {
  margin-top: 1.46em;
  font-size: 0.83em;
  line-height: 1.67;
}

.content-sub.road.right {
  margin-top: 1.98em;
  text-align: right;
}

.content-sub.road.dotted {
  margin-top: 0em;
  font-size: 0.8em;
  line-height: 1.75;
}

.content-sub.footer {
  margin-top: 0em;
  font-size: 0.7em;
  line-height: 1.67;
  text-transform: uppercase;
}

.content-sub.footer.right {
  margin-top: 1.98em;
  text-align: right;
}

.content-sub.footer.dotted {
  margin-top: 0em;
  font-size: 0.8em;
  line-height: 1.75;
}

.square-dot {
  position: absolute;
  left: -0.8em;
  top: 4.8em;
  right: auto;
  bottom: auto;
  z-index: 11;
  width: 1.5em;
  height: 1.5em;
  border-radius: 0.5em;
  background-color: #fff;
}

.square-dot.about {
  left: auto;
  top: 5.2em;
  right: -0.8em;
  bottom: auto;
}

.square-dot.about.meet-team {
  top: 4.7em;
}

.square-dot.about.road-1st {
  left: -0.8em;
  top: 4.7em;
  right: auto;
  border-radius: 0.25em;
}

.square-dot.about.meet-team-copy {
  top: 4.7em;
}

.square-dot.about.road-new {
  left: -0.8em;
  top: 4.7em;
  right: auto;
  border-radius: 0.25em;
}

.square-dot.about.last {
  top: 25em;
  background-color: #ff70ec;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._1st-icon {
  left: -0.8em;
  top: 12.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #ff70ec;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._1st-icon.road {
  background-color: #947cff;
}

.square-dot._2nd-icon {
  left: -0.8em;
  top: 20.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #b260ff;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._2nd-icon.road {
  top: 22.5em;
  background-color: #97ffe5;
}

.square-dot._3rd-icon {
  left: -0.8em;
  top: 28.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #000;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._3rd-icon.road {
  top: 33em;
  background-color: #52ffd4;
}

.square-dot._4th-icon {
  left: -0.8em;
  top: 36.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._4th-icon.road {
  top: 44.5em;
  background-color: #ffed68;
}

.square-dot._5th-icon {
  left: -0.8em;
  top: 44.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #52ffd4;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._6th-icon {
  left: -0.8em;
  top: 52.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #4dedb3;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._7th-icon {
  left: -0.8em;
  top: 60.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #9e8b77;
  box-shadow: inset 0 0 0 1px #fff;
}

.square-dot._8th-icon {
  left: -0.8em;
  top: 68.5em;
  right: auto;
  bottom: auto;
  border-radius: 0.2em;
  background-color: #627286;
  box-shadow: inset 0 0 0 1px #fff;
}

.abs-bar {
  position: absolute;
  left: auto;
  top: auto;
  right: 10em;
  bottom: -3em;
  padding: 1em 3em;
  border-bottom-left-radius: 2em;
  border-bottom-right-radius: 2em;
  background-color: #000;
  box-shadow: 0 0 0 0.1em #fff;
  font-weight: 300;
}

.abs-bar.about {
  left: 8em;
  right: auto;
  bottom: -4.3em;
}

.abs-bar.video {
  left: 36em;
  right: auto;
  bottom: -5.2em;
  z-index: 2222;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  background-color: #fff;
  box-shadow: 0 0 0 0.2em #000;
  color: #000;
}

.content-small {
  color: #fcfcfc;
  font-size: 0.7em;
  line-height: 1.36;
  text-align: center;
  text-transform: uppercase;
}

.content-small.parts {
  font-size: 0.82em;
}

.content-small.video {
  color: #000;
  font-size: 2em;
  font-weight: 300;
  text-transform: capitalize;
}

.rotated-text {
  position: absolute;
  top: 18em;
  right: 5em;
  display: inline-block;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #000;
  font-size: 1em;
  font-weight: 300;
  text-transform: uppercase;
}

.new-btn {
  background-color: #d72640;
  -webkit-transition: color 200ms ease, background-color 200ms ease,
    box-shadow 200ms ease;
  transition: color 200ms ease, background-color 200ms ease,
    box-shadow 200ms ease;
}

.new-btn:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 0.3em #d72640;
  color: #a3a2a2;
}

.adasaas {
  margin-top: 10em;
  color: #fff;
}

.parts-img {
  position: relative;
  z-index: 999;
  border-radius: 1em;
}

.parts-img.artist {
  height: 100%;
}

.parts-title {
  font-size: 1.2em;
  line-height: 1;
}

.display-first-copy {
  margin-top: 1em;
  margin-bottom: 0px;
  font-size: 1.3em;
  line-height: 1.25;
  font-weight: 300;
}

.bg-img {
  position: absolute;
  left: 0%;
  right: 0%;
  bottom: -7em;
  z-index: 1;
  height: 62em;
}

.team-img {
  width: 6em;
  margin-right: 2em;
  border-top-left-radius: 1em 1em;
  border-bottom-left-radius: 1em 1em;
}

.team-img.mod {
  margin-right: 0em;
}

.separator {
  width: 0.15em;
  height: 2.5em;
  margin-right: 3em;
  margin-left: 4em;
  background-color: #fff;
}

.separator.landscape {
  width: 50%;
  height: 0.15em;
  margin: 0.25em 0em;
  background-color: #000;
}

.horz-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 2em;
}

.horz-wrap.format {
  grid-column-gap: 1em;
}

.horz-wrap.footer {
  width: 43%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.horz-wrap.footer._2nd {
  width: 30%;
}

.gallery-video {
  z-index: 111;
  width: 100%;
  height: 70em;
}

.second-bg {
  position: absolute;
  left: 30em;
  bottom: 0em;
  width: 26.79em;
  height: auto;
}

.vert-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vert-wrap.road-map {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  color: #fff;
}

.vert-wrap.road-map._2nd {
  width: 43%;
}

.road-map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.vertical-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  grid-row-gap: 3.44em;
}

.dotted-container {
  width: 100%;
  margin-top: 2.05em;
  padding: 1.9em 2.12em 2.34em;
  border-style: dashed;
  border-width: 0.1em;
  border-color: #fff;
  border-radius: 1em;
}

.large-span {
  font-size: 2.5em;
  line-height: 0.2;
}

.social-link {
  width: 1.8em;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.social-link:hover {
  opacity: 0.8;
}

.third-img {
  position: absolute;
  left: 36.3em;
  top: 0em;
  right: auto;
  bottom: 0em;
  z-index: 1111;
  width: 35em;
  height: 100%;
}

.footer-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 2.12em 6.66em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 1em;
  background-color: #fff;
}

@media screen and (max-width: 991px) {
  body {
    font-size: 1.3vw;
  }

  .container.hero {
    padding-top: 6em;
    padding-right: 7em;
    padding-left: 7em;
  }

  .container.nav {
    padding: 1em 2.5em 1em 3em;
  }

  .container.about {
    padding-right: 3em;
    padding-left: 3em;
  }

  .container.artist {
    padding-right: 3em;
    padding-left: 3em;
  }

  .container.team {
    padding-right: 5em;
    padding-left: 5em;
  }

  .container.roadmap {
    padding-right: 5em;
    padding-left: 5em;
  }

  .nav-links {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .nav__link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.6em;
  }

  .nav-link {
    margin-right: 0em;
    margin-bottom: 1em;
    font-size: 1.3em;
  }

  .nav-menu {
    padding-top: 4em;
    padding-bottom: 4em;
    flex-direction: column;
    justify-content: center;
    border-top: 2px solid black;
    background-color: #fff;
  }

  .mobile-logo {
    display: block;
    width: 10.25em;
  }

  .main-logo {
    display: none;
  }

  .menu-button {
    padding: 0.5em;
  }

  .menu-button.w--open {
    background-color: transparent;
    color: #000;
  }

  .abs-bar.video {
    left: 24em;
  }

  .rotated-text {
    top: 23em;
    right: 0em;
  }

  .gallery-video {
    height: 50em;
  }

  .second-bg {
    left: 22em;
  }

  .third-img {
    left: 25em;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 1.5vw;
  }

  .container.team {
    padding-right: 3em;
    padding-left: 3em;
  }

  .container.roadmap {
    padding-right: 3em;
    padding-left: 3em;
  }

  .content-wrapper.about {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .content-wrapper.parts {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .content-wrapper.team {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .content-wrapper.road {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .content-left.white {
    width: 100%;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0em;
  }

  .content-left.parts {
    width: 100%;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0em;
  }

  .content-left.full {
    width: 100%;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0em;
  }

  .content-right.about {
    width: 100%;
    background-color: transparent;
  }

  .content-right.parts {
    width: 100%;
    background-color: transparent;
  }

  .content-right.full {
    width: 100%;
  }

  .square-dot {
    left: -0.8em;
    top: 4.5em;
    right: auto;
    bottom: auto;
  }

  .square-dot.about {
    top: auto;
    bottom: 22em;
  }

  .abs-bar {
    left: 22em;
    right: auto;
  }

  .abs-bar.about {
    left: 23em;
  }

  .abs-bar.video {
    left: 20em;
  }

  .rotated-text {
    top: 19em;
  }

  .bg-img {
    height: 70em;
  }

  .gallery-video {
    height: 45em;
  }

  .second-bg {
    left: 18em;
  }

  .third-img {
    left: 20em;
  }

  .content-right.thirdWrapper .third .separator {
    margin: 0 0.5em 0 0.5em;
  }
}

@media screen and (max-width: 479px) {
  body {
    font-size: 3.5vw;
  }

  .container.hero {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .container.nav {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .container.about {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .container.artist {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .container.team {
    padding: 0em 1.5em 7em;
  }

  .container.roadmap {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .container.footer {
    padding: 2.5em 1.5em;
  }

  .nav-link {
    font-size: 1.4em;
  }

  .nav-menu {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .content-wrapper.team {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .content-wrapper.road {
    padding-right: 1.5em;
    padding-left: 1.5em;
  }

  .content-wrapper.footer {
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .content-left.full {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content-left.full._2nd {
    margin-bottom: 6em;
  }

  .content-right.about {
    padding-right: 2em;
    padding-left: 2em;
    background-color: transparent;
  }

  .content-right.parts {
    padding-right: 2em;
    padding-left: 2em;
    border-top-right-radius: 1em;
    border-bottom-left-radius: 0em;
    background-color: #000;
  }

  .content-right.full {
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-row-gap: 1.5em;
  }

  .right-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .right-top._2nd {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .content-title.parts.small {
    font-size: 2.2em;
  }

  .content-title.parts.road {
    font-size: 2.2em;
  }

  .content-title.team-member {
    font-size: 2em;
  }

  .square-dot {
    top: 4.5em;
    bottom: auto;
  }

  .square-dot.about {
    top: 38em;
  }

  .square-dot.about.team {
    top: 4em;
  }

  .square-dot.about.meet-team {
    top: 4.3em;
  }

  .square-dot.about.road-new {
    top: 4.3em;
  }

  .square-dot.about.last {
    top: auto;
    bottom: 25em;
  }

  .square-dot._1st-icon {
    top: 22em;
  }

  .square-dot._1st-icon.road {
    top: 14em;
  }

  .square-dot._2nd-icon {
    top: 45.5em;
  }

  .square-dot._2nd-icon.road {
    top: 27em;
  }

  .square-dot._3rd-icon {
    top: 72em;
  }

  .square-dot._3rd-icon.road {
    top: 38.5em;
  }

  .square-dot._4th-icon {
    top: 98.5em;
  }

  .square-dot._4th-icon.road {
    top: 50.5em;
  }

  .square-dot._5th-icon {
    top: 123.5em;
  }

  .square-dot._5th-icon.road {
    top: 62.5em;
  }

  .square-dot._6th-icon {
    top: 144.5em;
  }

  .square-dot._7th-icon {
    top: 162.5em;
  }

  .square-dot._8th-icon {
    top: 171.5em;
  }

  .square-dot._9th-icon {
    top: 180.5em;
    background-color: rgb(200, 126, 179);
    border-radius: 0.2em;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .square-dot._10th-icon {
    top: 189.5em;
    background-color: #627286;
    border-radius: 0.2em;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .square-dot._11th-icon {
    top: 198em;
    background-color: #fb4011;
    border-radius: 0.2em;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .square-dot._12th-icon {
    top: 207em;
    background-color: #93c166;
    border-radius: 0.2em;
    box-shadow: inset 0 0 0 1px #fff;
  }

  .abs-bar {
    left: 5em;
    right: auto;
  }

  .abs-bar.about {
    left: 5.5em;
  }

  .abs-bar.video {
    left: 2.5em;
    right: auto;
    padding-right: 2em;
    padding-left: 2em;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
  }

  .rotated-text {
    left: 9.5em;
    top: auto;
    right: auto;
    bottom: 4em;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .bg-img {
    bottom: 0em;
    height: 65%;
  }

  .team-img {
    width: 7em;
    border-radius: 1em;
  }

  .separator.mob {
    width: 100%;
    height: 0.1em;
    margin-right: 0em;
    margin-left: 0em;
  }

  .horz-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 1.5em;
  }

  .horz-wrap.footer {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .horz-wrap.footer._2nd {
    width: 100%;
    margin-top: 1em;
  }

  .gallery-video {
    height: 18em;
  }

  .second-bg {
    left: 5em;
    width: 17em;
  }

  .vert-wrap.road-map._2nd {
    width: 100%;
    margin-top: 5em;
  }

  .road-map {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .third-img {
    display: none;
  }

  .footer-wrap {
    padding: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }

  .section.artist {
    padding-bottom: 0em;
  }

  .content-right.thirdWrapper {
    flex-direction: column;
    margin: 0;
  }

  .content-right.thirdWrapper .third {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0;
  }

  .content-right.thirdWrapper .third .separator {
    margin: 0 1em 0 1em;
  }
}
