.allowListWrapper {
  text-align: center;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.allowListWrapper .subtitle {
  margin-top: 10px;
}

.subtitle {
  font-family: "Sofia Pro", sans-serif;
}

.allowlistbtn {
  position: relative;
  color: white;
  background-color: black;
  font-size: 18px;
  border-radius: 20px;
  padding: 5px 15px;
  z-index: 10;
}

.allowlistbtnsize {
  max-width: 200px;
  text-decoration: none 
}

.allowlistbtn:hover {
  cursor: pointer;
}

.soldout {
  position: relative;
  z-index: 0;
  padding-bottom: 20px;
  height: auto;
  width: auto;
  max-height: 300px;
}

.checkAllowListStatusFailureWrapper .discord_img {
  width: 18px;
  height: 20px;
}

.discord{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
