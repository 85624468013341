.MuiPaper-root {
  text-align: center !important;
  border-radius: 20px !important;
}

.MuiPaper-root h2 {
  font-weight: 800;
  font-size: 24px;
}

.MuiPaper-root h2,
.MuiPaper-root p {
  font-family: "Sofia Pro", sans-serif !important;
}

.MuiPaper-root p a {
  color: black;
}

.MuiPaper-root p a:hover {
  cursor: pointer;
}

.MuiPaper-root img {
  width: 200px;
  margin: 0 auto;
}
