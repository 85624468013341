body {
  background-color: #000;
  font-family: Insanibc;
  color: #000;
  font-size: 1vw;
  line-height: 1.6;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2.5em;
  line-height: 1.2;
  font-weight: 400;
}

h4 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 1.67em;
  line-height: 1.2;
  font-weight: 400;
}

p {
  margin-bottom: 0px;
  font-size: 1.25em;
}

.page__wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 5.11em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.roadmap_container {
  position: relative;
  width: 100%;
  max-width: 85em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.78em;
  padding-left: 2.78em;
}

.roadmap_container.bg__shape-1 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
}

.navbar__wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.logo {
  height: 1.8em;
  width: 4.5em;
}

.logo1 {
  height: 2em;
  width: auto;
}

.brand {
  position: absolute;
  left: 50%;
  top: -60%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav__link {
  color: #000;
  font-size: 1.11em;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
}

.nav__link.m-0 {
  margin-right: 0em;
}

.header {
  height: 42.36em;
  background-color: #fff;
}

.header__wrap {
  position: relative;
}

.roadmap_container-md {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 75em;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2.78em;
  padding-left: 2.78em;
}

.header__image {
  width: 100%;
}

.header__text {
  position: absolute;
  left: auto;
  top: 50%;
  right: -5em;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  font-size: 1.2vw;
  font-weight: 600;
}

.section {
  position: relative;
  z-index: 1;
}

.section.bg-dark {
  background-color: #000;
  color: #fff;
}

.section.section__5 {
  z-index: 1;
  padding-top: 8em;
  padding-bottom: 8.61em;
}

.section.section__5 img {
  height: 102%;
}

.section.section--video {
  z-index: 2;
  padding-top: 0em;
}

.section.section__6 {
  z-index: 1;
  padding-top: 0;
}

.sm_text {
  color: #fff;
  font-size: 1em;
  font-weight: 400;
  text-decoration: none;
  font-family: "Sofia Pro", sans-serif;
}

.sm_text.text-color-dark {
  color: #000;
}

.squre {
  position: absolute;
  left: auto;
  top: 1.5em;
  right: -4.75em;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-radius: 0.28em;
  background-color: #fff;
}

.squre.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre.squre-4 {
  left: -5.5em;
  top: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.squre.squre-5 {
  left: auto;
  top: 50%;
  right: -5.5em;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.squre.faq__square {
  left: -5.5em;
  top: 2.5em;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.shop {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.shop-l {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 4.44em 4em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-radius: 2em 0px 0em 2em;
  background-color: #000;
  color: #fff;
}

.shop-r {
  position: relative;
}

.text__wrap {
  margin-top: 2.22em;
}

.p_text {
  font-size: 1.11em;
}

.shop_content-bottom {
  position: absolute;
  left: 50%;
  top: auto;
  right: auto;
  bottom: -4.4em;
  height: auto;
  padding: 1.11em 2.22em 1.5em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0px 0px 2.5em 2.5em;
  background-color: #000;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.heading__block {
  position: relative;
}

.teams {
  padding: 4.17em 4.17em 4.17em 5.5em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 1.39em;
  background-color: #000;
}

.teams__head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.teams__list {
  margin-top: 3.33em;
}

.team__item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 2.5em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team__item.mb-0 {
  margin-bottom: 0em;
}

.bg__shape {
  position: absolute;
  left: auto;
  top: -21.4em;
  right: -7.4em;
  bottom: auto;
  width: 55.56em;
}

.bg__image {
  position: absolute;
  left: 0%;
  top: 48%;
  right: auto;
  bottom: auto;
  width: 100%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.team__item-l {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.team__image {
  width: 6.25em;
}

.team__item-l-info {
  width: 12em;
  margin-left: 2.4em;
  border-right: 2px solid #fff;
}

.team__item-r {
  width: 34em;
  margin-left: 3.33em;
}

.team__item-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 1.39em;
}

.squre-__team {
  position: absolute;
  left: -6.35em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #fa64bb;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__team.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__team.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__team-2 {
  position: absolute;
  left: -6.35em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #617184;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__team-2.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__team-2.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__team-3 {
  position: absolute;
  left: -6.35em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #000;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__team-3.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__team-3.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__team-4 {
  position: absolute;
  left: -6.35em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #f3ebdd;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__team-4.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__team-4.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__team-5 {
  position: absolute;
  left: -6.35em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #73a864;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__team-5.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__team-5.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.background-video {
  width: 100%;
  height: 60em;
}

.video__bottom-info {
  position: absolute;
  left: 50%;
  top: auto;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 6em;
  max-width: 34em;
  padding-right: 4em;
  padding-left: 4em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0em 0px 2.78em 2.78em;
  background-color: #fff;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.video__bottom-info-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.sm__line {
  width: 1.39em;
  height: 1px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  background-color: #000;
}

.roadmap {
  position: relative;
  width: 100%;
  max-width: 68em;
  margin-right: auto;
  margin-left: auto;
  padding: 3.75em 4.44em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 1.39em;
  background-image: url("./images/roadmap.png");
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.roadmap_head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  flex-direction: row-reverse;
}

.roadmap__list {
  margin-top: 3.06em;
}

.roadmap__item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 24em;
  margin-bottom: 2.78em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.roadmap__item.roadmap__item-last {
  position: absolute;
  left: auto;
  top: 50%;
  right: 0%;
  bottom: auto;
  width: 28em;
  padding-right: 4em;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  text-align: right;
}

.roadmap__name {
  color: #fff;
}

.roadmap__text-wrap {
  margin-top: 1.39em;
  font-family: "Sofia Pro", sans-serif;
}

.squre-__r {
  position: absolute;
  left: -5.5em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #a2a6fd;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__r.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__r.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__r-2 {
  position: absolute;
  left: -5.5em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #93dffe;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__r-2.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__r-2.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__r-3 {
  position: absolute;
  left: -5.5em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #72fdad;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__r-3.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__r-3.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__r-4 {
  position: absolute;
  left: -5.5em;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #e7fd86;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__r-4.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__r-4.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.squre-__r-5 {
  position: absolute;
  left: auto;
  top: 50%;
  right: -1em;
  bottom: auto;
  width: 1.74em;
  height: 1.74em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 0.28em;
  background-color: #fe9c9d;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
}

.squre-__r-5.squre-2 {
  left: -4.75em;
  top: 0.5em;
  right: auto;
  bottom: auto;
}

.squre-__r-5.squre-3 {
  left: auto;
  top: 0.6em;
  right: -5em;
  bottom: auto;
}

.dotted__block {
  width: 23.96em;
  margin-top: 1.67em;
  padding: 1.67em;
  border-style: dashed;
  border-width: 2px;
  border-color: #fff;
  border-radius: 1.67em;
  text-align: left;
}

.footer {
  position: relative;
  z-index: 1;
  padding: 13.98em 7.17em 7.98em 7.17em;
  background-position: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer__wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.2em 6em 1.2em 6em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 1.39em;
  background-color: #fff;
  z-index: 3;
}

.bg__footer {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 0;
  width: 100%;
}

.stat__text {
  color: #000;
  font-size: 1.2em;
  font-weight: 400;
  text-decoration: none;
}

.stat__text.text-color-dark {
  color: #000;
}

.footer__l {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer__c {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.footer__logo {
  margin-right: 1.67em;
}

.footer__text {
  font-size: 1em;
}

.footer_r {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer_socials {
  display: -ms-grid;
  display: grid;
  margin-left: 2em;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.s__icon {
  height: 1.67em;
}

.v__shape {
  position: absolute;
  left: 40%;
  top: -11.1em;
  right: auto;
  bottom: auto;
  width: 28em;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

.img__fluid {
  width: 100%;
  height: 100%;
}

.faq {
  position: relative;
  width: 100%;
  max-width: 68em;
  margin-right: auto;
  margin-left: auto;
  padding: 3.75em 4.44em;
  border-style: solid;
  border-width: 2px;
  border-color: #fff;
  border-radius: 1.39em;
  background-color: #000;
}

.faq__list {
  margin-top: 2em;
}

.faq__item {
  position: relative;
  margin-bottom: 1em;
  padding: 1.67em;
  border-radius: 1em;
  background-color: #fff;
  cursor: pointer;
}

.faq__item-head {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.faq__name {
  font-size: 1.39em;
}

.faq__arr {
  height: 1em;
  width: auto;
}

.faq__item-content {
  overflow: hidden;
}

.faq__item-content-wrap {
  padding-top: 2em;
  padding-bottom: 1em;
}

.faq__item-content-wrap p {
  font-family: "Sofia Pro", sans-serif;
}

.image {
  position: absolute;
  left: 57%;
  top: -2%;
  right: auto;
  bottom: auto;
  width: 24em;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
}

@media screen and (max-width: 991px) {
  body {
    font-size: 10px;
  }

  h2 {
    font-size: 3em;
  }

  h4 {
    font-size: 1.8em;
  }

  p {
    font-size: 1.4em;
  }

  .navbar {
    height: 4em;
  }

  .roadmap_container {
    padding-right: 4em;
    padding-left: 4em;
  }

  .navbar__wrap {
    position: static;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .brand {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }

  .nav-menu {
    padding-top: 4em;
    padding-bottom: 4em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-top: 2px solid #000;
    background-color: #fff;
  }

  .nav__list {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .nav__link {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 1.6em;
  }

  .roadmap_container-md {
    padding-right: 4em;
    padding-left: 4em;
  }

  .header__text {
    display: none;
  }

  .section.section__3 {
    padding-top: 0em;
  }

  .section.section__6 {
    padding: 0em 2em;
  }

  .about__image {
    padding: 2em;
  }

  .about__text {
    font-size: 1.6em;
  }

  .about__content {
    padding: 2em 4em 4em;
  }

  .about__content-bottom {
    bottom: -6.8em;
  }

  .sm_text {
    font-size: 1.4em;
  }

  .squre.squre-2 {
    left: -3em;
  }

  .shop-l {
    padding-top: 4em;
    padding-right: 2em;
    padding-left: 2em;
  }

  .p_text {
    font-size: 1.4em;
  }

  .teams {
    padding-left: 4em;
  }

  .team__image {
    width: 8em;
  }

  .squre-__team {
    left: -5em;
  }

  .squre-__team-2 {
    left: -5em;
  }

  .squre-__team-3 {
    left: -5em;
  }

  .squre-__team-4 {
    left: -5em;
  }

  .squre-__team-5 {
    left: -5em;
  }

  .footer {
    background-image: url("../../pages/home/images/footer__bg.png");
    background-position: 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg__footer {
    display: none;
    height: 112em;
    -o-object-fit: cover;
    object-fit: cover;
  }

  .footer__text {
    font-size: 1em;
  }

  .img__fluid {
    -o-object-fit: cover;
    object-fit: cover;
  }

  .menu-button {
    padding: 0px;
    font-size: 35px;
  }

  .menu-button.w--open {
    background-color: transparent;
    color: #000;
  }

  .image {
    left: 62%;
    width: 28em;
  }

  .footer__wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .footer__l {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    height: 4em;
    width: 10em;
  }

  .header {
    height: auto;
    padding-top: 4em;
    padding-bottom: 4em;
  }

  .about {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .about__image {
    border-radius: 0em 0em 1.5em 1.5em;
  }

  .about__content {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .squre.squre-2 {
    top: 1em;
  }

  .shop {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    grid-auto-columns: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr 1.5fr;
    grid-template-columns: 1fr 1.5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .shop-l {
    border-style: solid;
    border-width: 2px 2px 0px;
    border-color: #fff;
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
    border-bottom-left-radius: 0em;
  }

  .shop-r {
    border-bottom-left-radius: 2em;
    border-bottom-right-radius: 2em;
  }

  .shop_content-bottom {
    bottom: -5em;
  }

  .team__item-l {
    width: 100%;
    border-bottom: 1px solid #fff;
  }

  .team__item-l-info {
    border-right-width: 0px;
  }

  .team__item-r {
    width: 100%;
    margin-left: 0em;
    padding: 1em;
    text-align: center;
  }

  .team__item-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .roadmap__item {
    width: 100%;
  }

  .roadmap__item.roadmap__item-last {
    position: relative;
    width: 100%;
    margin-bottom: 0em;
    padding-right: 0em;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    text-align: left;
  }

  .squre-__r-5 {
    left: -5.5em;
    top: 50%;
    right: auto;
    bottom: auto;
  }

  .dotted__block {
    width: 100%;
  }

  .footer {
    padding: 2em 2em 6em 2em;
  }

  .footer__logo {
    margin-right: 0em;
    margin-bottom: 2em;
  }

  .footer__text {
    font-size: 1.4em;
    text-align: center;
  }

  .footer__c {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .footer_r {
    margin-top: 2em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer_socials {
    margin-top: 1em;
    margin-left: 0em;
  }

  .s__icon {
    height: 2.4em;
  }

  .img__fluid {
    position: relative;
    z-index: 1;
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
  }

  .faq__name {
    font-size: 1.6em;
  }

  .image {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .footer__l,
  .footer_r {
    font-size: 10px;
  }

  .logo {
    height: 2em;
    width: 5em;
  }

  .roadmap_container {
    padding-right: 2em;
    padding-left: 2em;
  }

  .roadmap_container-md {
    padding-right: 2em;
    padding-left: 2em;
  }

  .about__content {
    padding-right: 2em;
    padding-left: 2em;
  }

  .sm_text.sm_text-1 {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .squre {
    right: -3em;
  }

  .squre.squre-3 {
    left: -5em;
    top: 0%;
    right: auto;
    bottom: auto;
  }

  .shop_content-bottom {
    z-index: 1;
  }

  .teams__head {
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .team__item-r {
    width: 100%;
  }

  .video__bottom-info {
    width: 90%;
    max-width: none;
  }

  .roadmap_head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer__text {
    text-align: center;
  }

  .faq__name {
    width: 85%;
  }
}

#w-node-_7ee9ac6d-1493-dc7e-e5c9-dceab0c4fcc8-e42e2513 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0b73c8d1-8a95-d6e4-d4b4-808e8ccec840-e42e2513 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_802a9b7c-4dea-c8fd-e301-66156f038022-e42e2513 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_9b9d1bcd-4bd7-ad5c-d50c-2e70abd0434b-e42e2513 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
