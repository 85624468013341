.section-mint-nfc {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff2e3;
  background-image: url("../../mint/images/airdrop-bg.png") !important;
  background-position: 50% 50%;
  background-size: cover;
}

.mint-div-nfc {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  height: 50%;
  padding-right: 40px;
  padding-left: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 32px;
  background-color: #fff;
  max-height: 500px;
  min-height: 400px;
  flex-direction: column;
  background-image: url("../../mint/images/airdrop-bg-inner.png") !important;
  background-position: 50% 50%;
  background-size: cover;
}

.mint-sub-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.mint-right {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 50%;
  width: auto;
  z-index: 1;
}

.mint-left {
  position: absolute;
  left: 15%;
  top: 20%;
  right: auto;
  bottom: 0%;
  height: 30%;
  width: auto;
  z-index: 3;
}

.mint_image {
  margin-bottom: 20px;
  max-height: 160px;
  max-width: 160px;
}

.mint_image1 {
  height: 30%;
  max-height: 400px;
  max-width: 400px;
}

.w-address-connected {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.text-block {
  font-weight: 700;
  text-align: center;
}

.text-block-2 {
  text-align: center;
  font-family: "Sofia Pro", sans-serif;
}

.text-block-2 span {
  font-family: Insanibc;
}

.text-block-3 {
  text-align: center;
}

.text-block-3-connected {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-block-3-connected span {
  display: inline-block;
  font-size: 20px;
  margin: 10px 0 20px;
}

.text-block-3-connected span.highlight {
  color: var(--color-red2);
}

.text-block-4 {
  text-align: center;
}

.text-block-4-connected {
  font-size: 24px;
  text-align: center;
}

.text-block-5 {
  text-align: center;
}

.button-5-connected {
  text-align: center;
  margin-top: 10px;
}

.button-5-connected button {
  width: 100%;
  font-size: 26px;
  padding: 5px 30px;
  border-radius: 24px;
  background: url("./images//mint-my-boxcat-background.png");
  background-size: 100% 100%;
}

.w-button {
  background-color: white;
  border-radius: 100px;
  border: 1px solid black;
  padding: 8px 20px;
  margin: 30px;
  font-size: 18px;
  transition-duration: 0.2s;
}
.w-button:hover {
  color: white;
  background-color: black;
}
.button-5-connected button:hover,
.w-button button:hover {
  cursor: pointer;
}

.plusMinusWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.plusMinusWrapper button {
  border-radius: 50%;
  background: black;
  color: white;
  width: 32px;
}

.defaultMintWrapper,
.ableToMintWrapper {
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  gap: 3px;
}

@media screen and (max-width: 991px) {
  .mint-right {
    height: 40%;
  }

  .mint-left {
    height: 25%;
  }

  .mint-div {
    padding-right: 30px;
    padding-left: 30px;
    height: 55%;
  }
}

@media screen and (max-width: 767px) {
  .mint-right {
    height: 30%;
  }

  .mint-left {
    left: 7%;
    top: 24%;
    height: 25%;
  }

  .mint-div {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 479px) {
  .mint-right {
    height: 20%;
  }

  .mint-left {
    left: 14%;
    top: 9%;
  }

  .mint-div {
    width: 90%;
    padding-right: 10px;
    padding-left: 10px;
    z-index: 1;
  }
}
