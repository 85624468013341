.media-player {
  position: fixed;
  left: 6em;
  bottom: 4em;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: fit-content;
  margin-right: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none;
  border-width: 1px;
  border-color: #0e0d0d;
  border-radius: 10px;
  background-color: #f6eee0;
  -o-object-fit: fill;
  object-fit: fill;
  box-shadow: 6px 5px 5px #535353;
  z-index: 10;
}

.media-player .cover {
  width: 5.2em;
  height: 5.2em;
  margin-right: 0.5em;
  margin-left: -3.6em;
}

.media-player .control {
  margin: 0 0.5em 0 0;
}

.media-player .title {
  color: black;
}

.media-player .subtitle {
  color: #535353;
}

.media-player .control-icon:hover {
  cursor: pointer;
}

.media-player .textWrapper {
  margin-right: 1.5em;
}

@media screen and (max-width: 991px) {
  .media-player {
    left: 6em;
  }
}

@media screen and (max-width: 479px) {
  .media-player {
    left: 1.5em;
  }

  .media-player .cover {
    width: 3em;
    height: 3em;
    margin-right: 0.5em;
    margin-left: 0;
  }

  .media-player .control {
    margin: 0 1em 0 0;
  }
}
